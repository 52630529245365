/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State, Action, namespace} from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types'
import { CustomerState } from '@/store/customer/types';
import {getLocalStorageService, getSessionStorageService} from '@/services/storage.service'
import router from "@/route/routing";
import * as locker from "@/store/locker.js"
import Dropbox from "@/store/dropbox";
import $ from "jquery";

const namespaceName: string = 'modelAccount';

const customer = namespace('customer');

export default class ModelAccountMyAccountPage extends Vue {
    @customer.Getter('getEmail') getEmail: any;
    @customer.Action('changeEmail') changeEmail: any;
    @customer.Action('fetchUserData') fetchUserData: any;
    @customer.Mutation('setIsLogin') setIsLogin: any;
    @customer.Action('logout') logout: any;

    @Getter('getModelData', {namespace: namespaceName}) getModelData: any;
	@Action('getModelPersonal', {namespace: namespaceName}) getModelPersonal: any;
	@Action('updateModelPersonal', {namespace: namespaceName}) updateModelPersonal: any;
	@Mutation('setModelCountry', {namespace: namespaceName}) setModelCountry: any;
	@Mutation('setFirstLoad', {namespace: namespaceName}) setFirstLoad: any;
	@Getter('getModelEmail', {namespace: namespaceName}) getModelEmail: any;
	@Getter('getModelName', {namespace: namespaceName}) getModelName: any;
	@Getter('getModelLastName', {namespace: namespaceName}) getModelLastName: any;
	@Getter('getModelPhone', {namespace: namespaceName}) getModelPhone: any;
	@Getter('getModelCountry', {namespace: namespaceName}) getModelCountry: any;
	@Getter('getModelRegion', {namespace: namespaceName}) getModelRegion: any;
	@Getter('getModelCity', {namespace: namespaceName}) getModelCity: any;
	@Getter('getModelAddress', {namespace: namespaceName}) getModelAddress: any;
	@Getter('getModelPostcode', {namespace: namespaceName}) getModelPostcode: any;
	@Getter('getFirsLoadState', {namespace: namespaceName}) getFirsLoadState: any;
	@Getter('getModelLogin', {namespace: namespaceName}) getModelLogin: any;

	@Action('getModelInfo', {namespace: namespaceName}) getModelInfo: any;
	@Getter('modelInfo', {namespace: namespaceName}) modelInfo: any;

    @Action('getRegions', {namespace: namespaceName}) getRegions: any;
    @Action('getCountries', {namespace: namespaceName}) getCountries: any;
    @Action('getCities', {namespace: namespaceName}) getCities: any;

    @Getter('getCountriesData', {namespace: namespaceName}) getCountriesData: any;
    @Getter('getRegionsData', {namespace: namespaceName}) getRegionsData: any;
    @Getter('getCitiesData', {namespace: namespaceName}) getCitiesData: any;

    @Mutation('clearRegionsData', {namespace: namespaceName}) clearRegionsData: any;
    @Mutation('clearCountriesData', {namespace: namespaceName}) clearCountriesData: any;
    @Mutation('clearCitiesData', {namespace: namespaceName}) clearCitiesData: any;

    //Сохранение
    @Action('setContactInformation', {namespace: namespaceName}) setContactInformation: any;


    editButtonClass = "btn-gray";
    avatar = "";

	$refs!: {
        email: HTMLInputElement
	};

    modelContacts: any = {
        country: "",
        region: "",
        city: "",
    };

    Dropbox(e : any) {
        Dropbox(e);
    }

    //Страна
    ChangeCountry(e : Event)
    {
        console.log('ChangeCountry', this.modelContacts.country);

        if (this.modelContacts.country)
        {
            this.getRegions(this.modelContacts.country);

            this.modelContacts.region = "";
            this.modelContacts.city = "";

            $('#region-name').val('');
            $('#city-name').val('');

            this.clearCitiesData();
        }

        this.change();
    }

    //Регион
    ChangeRegion(e : Event)
    {
        console.log('ChangeRegion', this.modelContacts.region);

        if (this.modelContacts.region)
        {
            this.getCities({
                country_id: this.modelContacts.country,
                region_id : this.modelContacts.region
            });

            this.modelContacts.city = "";
            $('#city-name').val('');
        }

        this.change();
    }

    change()
    {
        this.editButtonClass = "btn-violet"
    }

    //id гео
    profileData = {
	    email : "",
        country : "",
        region : "",
        city : "",
	};



	//Сохранение данных
	save()
    {
        if (this.editButtonClass != "btn-violet")
            return;

        locker.Show();

        this.changeEmail({email : this.profileData.email});

        var data = {
            name: this.getModelData.name,
            last_name: this.getModelData.last_name,
            phone: this.getModelData.phone,

            country_id: this.modelContacts.country,
            region_id: this.modelContacts.region,
            city_id: this.modelContacts.city,

            address: this.getModelData.address,
            postcode: this.getModelData.postcode,
        };

        this.setContactInformation(data).then(function () {
            locker.Hide();
        });

        this.editButtonClass = "btn-gray";


    }

	mounted() {
        var _this = this;

        this.fetchUserData().then(function () {
            _this.profileData.email = _this.getEmail;
        });

        this.getModelInfo().then(function () {
            console.log(_this.modelInfo);

            if (_this.modelInfo.avatar)
                _this.avatar = "background-image: url('"+ _this.modelInfo.avatar + "')";
        });

        //Выгрузка информации о выбранных городах + списки других доступных геоданных
        this.getModelPersonal().then(function ()
		{
            _this.getCountries().then(function () {
                if (_this.getModelData.country_id)
                {
                    _this.modelContacts.country = _this.getModelData.country_id;
                    $('#country-name').val(_this.getCountriesData.find((x : any) => x.id == _this.getModelData.country_id).name);


                    _this.getRegions(_this.getModelData.country_id).then(function () {
                        if (_this.getModelData.region_id)
                        {
                            _this.modelContacts.region = _this.getModelData.region_id;
                            $('#region-name').val(_this.getRegionsData.find((x : any) => x.id == _this.getModelData.region_id).name);

                            _this.getCities({
                                country_id: _this.getModelData.country_id,
                                region_id : _this.getModelData.region_id
                            }).then(function () {
                                if (_this.getModelData.city_id)
                                {
                                    _this.modelContacts.city = _this.getModelData.city_id;
                                    $('#city-name').val(_this.getCitiesData.find((x : any) => x.id == _this.getModelData.city_id).name);
                                }
                            });
                        }
                    });
                }
            });
        });
	}
}
